import { useState, useEffect, useRef } from 'react'

export const useVWOVariant = (campaignId) => {
  const [variant, setVariant] = useState(null)
  const foundVariant = useRef(false)

  useEffect(() => {
    if (foundVariant.current) {
      return
    }

    const checkVWOData = () => {
      console.log('Checking VWO Data...')
      if (window._vwo_exp && window._vwo_exp[campaignId]) {
        const userVariant = window._vwo_exp[campaignId].combination_selected

        console.log('VWO Data found:', userVariant)
        if (userVariant !== null) {
          setVariant(userVariant)
          clearInterval(interval)
          foundVariant.current = true
        }
      }
    }

    const interval = setInterval(checkVWOData, 1000)
    checkVWOData()

    return () => clearInterval(interval)
  }, [campaignId])

  return variant
}
