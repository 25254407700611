import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { Hero } from '@roar/components'
import { mapper } from '../common/utils'
import { formatImage } from './ImageWrapper'
import { useVWOVariant } from '../hooks/useVWO'
import { SectionDescription } from '@roar/components'
import RichTextRenderer from '../components/RichTextRenderer'

const HEADER_PROPS_MAP = {
  headline: ['headline'],
  description: (value) => {
    if (value.description) {
      return value.description
    }

    return value && value.content && value.content.childMarkdownRemark ? value.content.childMarkdownRemark.html : ''
  },
  contentRich: ['contentRich'],
  focalPoint: (value) => {
    if (value.focalPoint) {
      return {
        x: value.focalPoint.focalPoint.x3,
        y: value.focalPoint.focalPoint.y3,
      }
    }
    return {
      x: 0,
      y: 0,
    }
  },
  image: (value) => formatImage(value, true),
  video: ['video'],
  colorScheme: ['style', 'colorScheme'],
  style: ({ style = {} }) => {
    if (!style) return {}
    return Object.keys(style).reduce((result, item) => {
      if (style[item]) {
        result[item] = style[item]
      }

      return result
    }, {})
  },
}
const HeroWrapper = (props) => {
  const { style, contentRich, ...rest } = mapper(props, HEADER_PROPS_MAP)

  return <Hero {...style} content={<RichTextRenderer content={contentRich} />} {...rest} />
}

export default HeroWrapper

export const hero = graphql`
  fragment Hero on ContentfulHero {
    id
    name
    image {
      gatsbyImageData(layout: CONSTRAINED, width: 1440, quality: 90)
    }

    headline
    content {
      content
      childMarkdownRemark {
        html
      }
    }
    contentRich {
      raw
      references {
        __typename
        ... on ContentfulHeading {
          ...HeadingFields
        }
        ... on ContentfulLayoutGrid {
          contentful_id
          ...Grid
        }
      }
    }
    focalPoint {
      focalPoint {
        x3: xp
        y3: yp
      }
    }
    style {
      bg
      colorScheme
      pb
      pt
    }
  }
`
